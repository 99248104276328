const LAMBDA_URL = process.env.REACT_APP_LAMBDA_URL || "https://p7bvuo7b6xnptly4fyodgfhmcq0huuul.lambda-url.us-east-1.on.aws";

if (!LAMBDA_URL) {
  console.error('Lambda URL is not defined in environment variables. Please check your .env file.');
}

export const signupForAffirmations = async ({
    firstName,
    lastName,
    phoneNumber,
    goal
}) => {
  try {
    if (!LAMBDA_URL) {
      throw new Error('Lambda URL is not configured');
    }

    console.log('Sending signup request with data:', {
      firstName,
      lastName,
      phoneNumber,
      goal
    });

    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        requestType: 'signup',
        body: JSON.stringify({ 
          firstName,
          lastName,
          phoneNumber: phoneNumber.replace(/\D/g, ''),
          goal 
        })
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ error: 'Unknown error occurred' }));
      console.error('Signup failed:', errorData);
      throw new Error(errorData.error || 'Signup failed');
    }
    
    const data = await response.json();
    console.log('Signup successful:', data);
    return data;
  } catch (error) {
    console.error('Error during signup:', error);
    throw error;
  }
};
