import React, { useState } from 'react';
import { CheckCircle, AlertCircle, X } from 'lucide-react';
import { signupForAffirmations } from '../services/api';

// Phone formatting utility functions
const formatPhoneNumber = (value) => {
  if (!value) return value;
  
  // Remove all non-digits
  const phoneNumber = value.replace(/[^\d]/g, '');
  
  // Remove the leading 1 if it exists for formatting
  const digits = phoneNumber.startsWith('1') ? phoneNumber.substring(1) : phoneNumber;
  
  if (digits.length < 4) return digits;
  if (digits.length < 7) {
    return `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
  }
  return `(${digits.slice(0, 3)}) ${digits.slice(3, 6)}-${digits.slice(6, 10)}`;
};

// Format for display with +1
const formatPhoneNumberForDisplay = (value) => {
  const formatted = formatPhoneNumber(value);
  if (!formatted) return '';
  return `+1 ${formatted}`;
};

// Format for API submission
const formatPhoneNumberForSubmission = (value) => {
  const digits = value.replace(/[^\d]/g, '');
  return digits.startsWith('1') ? digits : `1${digits}`;
};

const AffirmationSignup = ({ isDarkMode, isOpen, onClose }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [goal, setGoal] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [goalError, setGoalError] = useState('');
    const [nameError, setNameError] = useState('');

    // Name validation
    const isValidName = (name) => {
        // Check if name contains only letters and is at least 2 characters
        const nameRegex = /^[A-Za-z]{2,}$/;
        return nameRegex.test(name);
    };

    const handleNameChange = (value, type) => {
        if (type === 'first') {
            setFirstName(value);
        } else {
            setLastName(value);
        }

        if (!value) {
            setNameError(`${type === 'first' ? 'First' : 'Last'} name is required`);
        } else if (!isValidName(value)) {
            setNameError(`Please enter a valid ${type === 'first' ? 'first' : 'last'} name (letters only)`);
        } else if (type === 'last' && !isValidName(firstName)) {
            setNameError('Please enter a valid first name');
        } else if (type === 'first' && !isValidName(lastName)) {
            setNameError('Please enter a valid last name');
        } else {
            setNameError('');
        }
    };

    // Phone number validation
    const isValidPhoneNumber = (number) => {
        // Remove all non-digits and check if we have exactly 10 or 11 digits (with leading 1)
        const digits = number.replace(/[^\d]/g, '');
        if (digits.length === 10) return true;
        if (digits.length === 11 && digits.startsWith('1')) return true;
        return false;
    };

    const handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const formatted = formatPhoneNumber(inputValue);
        setPhoneNumber(formatted);

        if (!inputValue) {
            setPhoneError('Phone number is required');
        } else if (!isValidPhoneNumber(inputValue)) {
            setPhoneError('Please enter a valid 10-digit US phone number');
        } else {
            setPhoneError('');
        }
    };

    // Basic goal validation
    const isValidGoal = (text) => {
        // Check if the goal has at least 2 words and each word is at least 2 characters
        const words = text.trim().split(/\s+/);
        return words.length >= 2 && words.every(word => word.length >= 2);
    };

    const handleGoalChange = (e) => {
        const value = e.target.value;
        setGoal(value);

        if (!value) {
            setGoalError('Goal is required');
        } else if (!isValidGoal(value)) {
            setGoalError('Please enter a meaningful goal (at least 2 words)');
        } else {
            setGoalError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setPhoneError('');
        setGoalError('');

        // Validate all fields
        if (!firstName || !lastName || !phoneNumber || !goal) {
            setError('Please fill in all fields');
            return;
        }

        if (!isValidName(firstName) || !isValidName(lastName)) {
            setNameError('Please enter valid first and last names');
            return;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            setPhoneError('Please enter a valid 10-digit US phone number');
            return;
        }

        if (!isValidGoal(goal)) {
            setGoalError('Please enter a meaningful goal (at least 2 words)');
            return;
        }

        try {
            await signupForAffirmations({
                firstName,
                lastName,
                phoneNumber: formatPhoneNumberForSubmission(phoneNumber),
                goal
            });

            setSubmitted(true);
            setError('');
        } catch (err) {
            setError(`Something went wrong. Please try again: ${err.message}`);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto">
            {/* Backdrop */}
            <div
                className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
                onClick={onClose}
            />

            {/* Modal */}
            <div className="flex min-h-screen items-center justify-center p-4">
                <div className={`relative w-full max-w-md transform overflow-hidden rounded-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-6 text-left shadow-xl transition-all`}>
                    {/* Close button */}
                    <button
                        onClick={onClose}
                        className={`absolute right-4 top-4 p-1 rounded-full hover:bg-gray-100 ${isDarkMode ? 'text-gray-400 hover:bg-gray-700' : 'text-gray-500'}`}
                    >
                        <X className="w-5 h-5" />
                    </button>

                    {submitted ? (
                        <div className="text-center py-6">
                            <CheckCircle className="w-16 h-16 mx-auto text-green-500 mb-4" />
                            <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                                You're all set!
                            </h3>
                            <p className={`${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                Get ready to receive daily affirmations to support your journey.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className="text-center mb-8">
                                <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                                    Daily Affirmations
                                </h2>
                                <p className={`mt-2 ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                    Receive personalized daily affirmations to keep you motivated
                                </p>
                            </div>

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="flex gap-4">
                                    <div className="flex-1 space-y-1">
                                        <input
                                            type="text"
                                            placeholder="First Name"
                                            value={firstName}
                                            onChange={(e) => handleNameChange(e.target.value, 'first')}
                                            className={`w-full px-4 py-2 rounded-lg border ${isDarkMode
                                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                                                } ${nameError && !isValidName(firstName) ? 'border-red-500' : ''} focus:outline-none focus:ring-2 focus:ring-purple-500`}
                                        />
                                    </div>
                                    <div className="flex-1 space-y-1">
                                        <input
                                            type="text"
                                            placeholder="Last Name"
                                            value={lastName}
                                            onChange={(e) => handleNameChange(e.target.value, 'last')}
                                            className={`w-full px-4 py-2 rounded-lg border ${isDarkMode
                                                    ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                                    : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                                                } ${nameError && !isValidName(lastName) ? 'border-red-500' : ''} focus:outline-none focus:ring-2 focus:ring-purple-500`}
                                        />
                                    </div>
                                </div>
                                {nameError && (
                                    <p className="text-sm text-red-500 -mt-4">{nameError}</p>
                                )}

                                <div className="space-y-1">
                                    <input
                                        type="tel"
                                        placeholder="Enter your phone number"
                                        value={formatPhoneNumberForDisplay(phoneNumber)}
                                        onChange={handlePhoneNumberChange}
                                        className={`w-full px-4 py-2 rounded-lg border ${isDarkMode
                                                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                                : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                                            } ${phoneError ? 'border-red-500' : ''} focus:outline-none focus:ring-2 focus:ring-purple-500`}
                                    />
                                    {phoneError && (
                                        <p className="text-sm text-red-500">{phoneError}</p>
                                    )}
                                </div>

                                <div className="space-y-1">
                                    <input
                                        type="text"
                                        placeholder="What's your main goal? (e.g., Learn Programming)"
                                        value={goal}
                                        onChange={handleGoalChange}
                                        className={`w-full px-4 py-2 rounded-lg border ${isDarkMode
                                                ? 'bg-gray-700 border-gray-600 text-white placeholder-gray-400'
                                                : 'bg-white border-gray-300 text-gray-900 placeholder-gray-500'
                                            } ${goalError ? 'border-red-500' : ''} focus:outline-none focus:ring-2 focus:ring-purple-500`}
                                    />
                                    {goalError && (
                                        <p className="text-sm text-red-500">{goalError}</p>
                                    )}
                                </div>

                                {error && (
                                    <div className="flex items-center space-x-2 text-red-600">
                                        <AlertCircle className="w-5 h-5" />
                                        <span className="text-sm">{error}</span>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className={`w-full py-2 px-4 rounded-lg font-medium transition-colors duration-200 ${isDarkMode
                                            ? 'bg-purple-600 hover:bg-purple-700 text-white'
                                            : 'bg-purple-500 hover:bg-purple-600 text-white'
                                        }`}
                                >
                                    Sign Up for Daily Affirmations
                                </button>
                            </form>

                            <div className="mt-6 text-center text-sm text-gray-500">
                                <p>
                                    Your data is secure. Message and data rates may apply.
                                    <br />
                                    Reply STOP at any time to unsubscribe.
                                </p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AffirmationSignup;