import React, { createContext, useState } from 'react';
import TikTokSlider from './TikTokSlider';
import DonationCard from './DonationCard';
import YoutubeSlider from './YoutubeSlider';
import ArticleSection from './ArticleSection';
import ContactSection from './ContactSection';
import CombinedSlider from './Gigs';
import Navigation from './Navigation';
import Footer from './Footer';
import Hero from './Hero';
import CombinedPortfolio from './CombinedPortfolio';
import FeaturedContent from './FeaturedContent';
import LatestEpisodes from './LatestEpisodes';
import { themes } from '../data/index';
import AffirmationSignup from './AffirmationSignup';

const defaultThemeContext = {
  isDarkMode: false,
  currentTheme: themes.default,
  themeName: 'default',
  changeTheme: () => { },
  toggleDarkMode: () => { }
};

const ThemeContext = createContext(defaultThemeContext);

export default function GamingWebsite() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [themeName, setThemeName] = useState('default');
  const [isModalOpen, setIsModalOpen] = useState(false);

  React.useEffect(() => {
    const savedTheme = localStorage.getItem('theme-mode');
    const savedPalette = localStorage.getItem('theme-palette');
    if (savedTheme === 'dark') setIsDarkMode(true);
    if (savedPalette && themes[savedPalette]) setThemeName(savedPalette);
  }, []);

  React.useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      setIsDarkMode(true);
    }
  }, []);

  // const toggleDarkMode = () => {
  //   setIsDarkMode(!isDarkMode);
  //   localStorage.setItem('theme', !isDarkMode ? 'dark' : 'light');
  // };
  const toggleDarkMode = () => {
    setIsDarkMode(prev => {
      const newValue = !prev;
      localStorage.setItem('theme', !isDarkMode ? 'dark' : 'light');
      localStorage.setItem('theme-mode', newValue ? 'dark' : 'light');
      return newValue;
    });
  };

  const changeTheme = (newTheme) => {
    if (themes[newTheme]) {
      setThemeName(newTheme);
      localStorage.setItem('theme-palette', newTheme);
    }
  };

  const themeContext = {
    isDarkMode,
    currentTheme: themes[themeName],
    themeName,
    changeTheme,
    toggleDarkMode
  };

  return (
    <ThemeContext.Provider value={themeContext}>
      <div className={`min-h-screen ${isDarkMode ? themes[themeName].background.dark : themes[themeName].background.light}`}>

        {/* <div className={`min-h-screen ${isDarkMode ? 'bg-gray-900' : 'bg-gray-50'}`}> */}
        <Navigation isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
        <div className="md:hidden fixed top-4 right-4 z-50 flex gap-2">
          <select
            className="rounded-md px-3 py-1 text-sm bg-white dark:bg-gray-800"
            value={themeName}
            onChange={(e) => changeTheme(e.target.value)}
          >
            <option value="default">Default</option>
            <option value="techDark">Tech Dark</option>
            <option value="cyberpunk">Cyber Punk</option>
            <option value="gaming">Gaming</option>
            <option value="emerald">Emerald</option>
            <option value="rose">Rose</option>
            <option value="hosea">Hosea</option>
          </select>
          <button
            onClick={toggleDarkMode}
            className="rounded-md px-3 py-1 text-sm bg-white dark:bg-gray-800"
          >
            {isDarkMode ? '☀️' : '🌙'}
          </button>
        </div>
        {/* Main Content */}
        <main className={`
        max-w-7xl 
        mx-auto 
        px-4 
        pb-8 
        relative
        -mt-[1px]
      `}>
          <Hero isDarkMode={isDarkMode} />
          {/* <TikTokSlider /> */}
          {/* Affirmations Section */}
          <FeaturedContent isDarkMode={isDarkMode} />
          <CombinedPortfolio isDarkMode={isDarkMode} />
          <ArticleSection isDarkMode={isDarkMode} />
          <div className={`py-12 px-4 sm:px-6 lg:px-8 ${isDarkMode ? 'bg-gray-800' : 'bg-gray-50'}`}>
            <div className="max-w-7xl mx-auto">
              <div className="text-center mb-12">
                <h1 className={`text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                  Daily Affirmations
                </h1>
                <p className={`text-xl ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                  Start your journey to personal growth with daily motivation
                </p>
                <button
                  onClick={() => setIsModalOpen(true)}
                  className={`mt-8 px-6 py-3 rounded-lg font-medium transition-colors duration-200 ${isDarkMode
                      ? 'bg-purple-600 hover:bg-purple-700 text-white'
                      : 'bg-purple-500 hover:bg-purple-600 text-white'
                    }`}
                >
                  Get Daily Affirmations
                </button>
              </div>
            </div>
          </div>

          {/* Modal Component */}
          <AffirmationSignup
            isDarkMode={isDarkMode}
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
          <DonationCard isDarkMode={isDarkMode} />
          <CombinedSlider isDarkMode={isDarkMode} />
          <LatestEpisodes isDarkMode={isDarkMode} />
          <YoutubeSlider isDarkMode={isDarkMode} />
          <ContactSection isDarkMode={isDarkMode} />
        </main>
        <Footer isDarkMode={isDarkMode} />
      </div>
    </ThemeContext.Provider>
  );
}